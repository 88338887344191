import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _loadingSubject = signal<boolean>(false);

    setLoadingStatus(value: boolean) {
        this._loadingSubject.set(value);
    }

    getLoadingStatus(): boolean {
        return this._loadingSubject();
    }
}
