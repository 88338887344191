import { Injectable, inject } from '@angular/core';
import { AlertService } from 'app/core/alert/alert.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    private _alertService = inject(AlertService);

    handleApiError(error: any): void {
        const errorMessage = this.extractErrorMessageKey(error);
        if (
            (error.status === 0 && error.url?.includes('checkin')) ||
            error.url?.includes('app/user') ||
            error.status === 200
        ) {
            localStorage.removeItem('clientId');
            localStorage.removeItem('id');
            localStorage.removeItem('access_token');
            return;
        }
        this._alertService.errorAlert('apiError', errorMessage + '_msg', errorMessage + '_title');
    }

    // returned error key can be used to access error message from transloco file
    private extractErrorMessageKey(error: any): string {
        return error.status || 'unknown';
    }
}
