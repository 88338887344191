import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from 'app/core/error-handling/error-handler.service';
import { UrlBuilderService } from 'app/core/urls/url-builder.service';
import { ProcessService } from 'app/modules/bp/process.service';
import { TypeOfProcess } from '@em4cloud/process-tree';
import { TaskProcessService } from 'app/modules/task/task-process.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
// @Injectable()
export class XmlInterceptor {
    errorOccures$: Subject<void> = new Subject();

    static readonly fileType = 'fileType';
    static readonly picture = 'PICTURE';

    constructor() {
        var send = window.XMLHttpRequest.prototype.send;
        const urlBuilderService = inject(UrlBuilderService);
        const processService = inject(ProcessService);
        const taskProcessService = inject(TaskProcessService);
        const errorHandlerService = inject(ErrorHandlerService);
        const _translocoService = inject(TranslocoService);
        let errorOccures = this.errorOccures$;

        function sendReplacement(this: any, data: FormData) {
            if (this.onreadystatechange) {
                this._onreadystatechange = this.onreadystatechange;
            }
            const url = this.__zone_symbol__xhrURL;

            if (url.indexOf('/backend/api/upload-file') > 0) {
                if (data) {
                    if (!data.has(XmlInterceptor.fileType)) {
                        data.append(XmlInterceptor.fileType, XmlInterceptor.picture);
                    }

                    this.setRequestHeader(
                        'Authorization',
                        'Bearer ' + localStorage.getItem('access_token')
                    );

                    if (processService.selectedProcess()?.id) {
                        this.setRequestHeader('x-bp-id', processService.selectedProcess().id);
                        this.setRequestHeader('x-user-lang', _translocoService.getActiveLang());

                        const taskId = taskProcessService.currentModel?.model?.id;
                        if (taskId && processService.view === TypeOfProcess.Task) {
                            this.setRequestHeader('x-task-id', taskId);
                        }
                    }

                    if (localStorage.getItem('organisation')) {
                        this.setRequestHeader('x-org-id', localStorage.getItem('organisation'));
                    }

                    const newUrl = urlBuilderService.buildUrlForInterceptor(url);
                    this.__zone_symbol__xhrURL = newUrl;
                }
            }

            const originalOnReadyStateChange = this.onreadystatechange;

            this.onreadystatechange = () => {
                if (this.readyState === 4) {
                    if (this.status >= 200 && this.status < 300) {
                        // Request was successful
                        // You can add custom success handling here
                    } else {
                        // Request failed
                        errorHandlerService.handleApiError({
                            status: this.status,
                        });
                        errorOccures.next();
                    }
                }

                // Call the original onreadystatechange if it exists
                if (originalOnReadyStateChange) {
                    originalOnReadyStateChange.apply(this);
                }
            };

            return send.apply(this, arguments as any);
        }

        window.XMLHttpRequest.prototype.send = sendReplacement;
    }
}
