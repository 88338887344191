import { Inject, Injectable } from '@angular/core';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { StorageService } from 'app/services/storage/storage.service';
import _ from 'lodash';
import { UserService } from '../user/user.service';
import { urls } from './urls.data';

@Injectable({
    providedIn: 'root',
})
export class UrlBuilderService {
    basePathInGeneratedClient: string;
    basicClientIdToBeReplaced: string;

    prodEnvironment;
    host;
    readonly urls: unknown;
    clientId: string;

    constructor(
        private _userService: UserService,
        private storageService: StorageService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {
        this.basePathInGeneratedClient = this.appConfig.originalHost;
        this.basicClientIdToBeReplaced = this.appConfig.basicClientIdToBeReplaced;
        this.prodEnvironment = this.appConfig.prod;
        this.host = this.appConfig.apiHost;

        this.urls = urls;
        this.clientId = this._userService.id;
        this._userService.user$.subscribe(user => {
            this.clientId = user.keyspace;
        });
    }

    // building base url
    public buildUrl(path: string): string {
        /* getting the path from the urls object using '.' string notation
         * example, path - "bp.bpls"
         */
        let url = this.host + _.get(urls, path);

        // replacing with the userId
        url = url.replace(/{userId}/, this.clientId);

        return url;
    }

    public buildUrlForInterceptor(url: string, givenHost?: string): string {
        const host = givenHost ? givenHost : this.host;
        if (!this.clientId) {
            // this.clientId = localStorage.getItem('id');
            this.clientId = this.storageService.getId();
        }

        if (url.includes(this.basePathInGeneratedClient)) {
            url = url.replace(this.basePathInGeneratedClient, host);
        } else if (url.includes(this.appConfig.apiHost)) {
            url = url.replace(this.appConfig.apiHost, host);
        } else if (url.includes('https://auth.em4cloud-dev.com')) {
            url = url.replace('https://auth.em4cloud-dev.com', this.appConfig.issuer);
        }
        url = url.replace(this.basicClientIdToBeReplaced, this.clientId);

        return url;
    }
}
